// import { ChainId } from '@pancakeswap-libs/sdk';
import { ChainId } from '@spookyswap/sdk';
import { Configuration } from './tomb-finance/config';
import { BankInfo } from './tomb-finance';

import { http, createConfig } from 'wagmi'
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  safeWallet,
  trustWallet,
  rabbyWallet,
} from '@rainbow-me/rainbowkit/wallets';

export const soneiumTestnet = {
  id: 8453,
  name: 'Base',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://mainnet.base.org'] },
  },
  blockExplorers: {
    default: { name: 'Basescan', url: 'https://basescan.org' },
  },
}

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        rabbyWallet,
        metaMaskWallet,
        rainbowWallet,
        // walletConnectWallet,
        coinbaseWallet,
        injectedWallet,
        safeWallet,
        trustWallet,
      ],
    },
  ],
  {
    appName: 'Obelisk Finance',
    projectId: 'YOUR_PROJECT_ID',
  }
);

export const configWagmi = createConfig({
  chains: [soneiumTestnet],
  ssr: true,
  connectors,
  transports: {
    [soneiumTestnet.id]: http(),
  },
})

const configurations: { [env: string]: Configuration } = {
  production: {
    chainId: ChainId.FTMTESTNET,
    networkName: 'Fantom Opera Testnet',
    ftmscanUrl: 'https://testnet.ftmscan.com',
    defaultProvider: 'https://rpc.testnet.fantom.network/',
    deployments: require('./tomb-finance/deployments/deployments.testing.json'),
    externalTokens: {
      FUSDT: ['0xb7f24e6e708eabfaa9e64b40ee21a5adbffb51d6', 6],
      BOO: ['0x14f0C98e6763a5E13be5CE014d36c2b69cD94a1e', 18],
      ZOO: ['0x2317610e609674e53D9039aaB85D8cAd8485A7c5', 0],
      SHIBA: ['0x39523112753956d19A3d6a30E758bd9FF7a8F3C0', 9],
      'USDT-FTM-LP': ['0xE7e3461C2C03c18301F66Abc9dA1F385f45047bA', 18],
      'ETH-OBELISK-LP': ['0x5505f80099d259d1c6f6feceb7512278eb33ed2f', 18],
      'ETH-GOBELISK-LP': ['0x20bc90bB41228cb9ab412036F80CE4Ef0cAf1BD5', 18],
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
  development: {
    chainId: ChainId.BASE,
    networkName: 'Base',
    ftmscanUrl: 'https://basescan.org',
    defaultProvider: 'https://mainnet.base.org',
    deployments: require('./tomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      OBELISK: ['0xAb97B8D6dBEFC03dfEC9672Ab01B3Dd724b73E16', 18],
      GOBELISK: ['0x29D0762f7bE8409d0aC34A3595AF62E8c0120950', 18],
      BOBELISK: ['0xcfB9fDCd480Bc731833257eb982718cAb5aD2cE6', 18],

      ETH: ['0x4200000000000000000000000000000000000006', 18],
      "WETH": ["0x4200000000000000000000000000000000000006", 18],
      "TAROT": ["0xF544251D25f3d243A36B07e7E7962a678f952691", 18],
      "WELL": ["0xFF8adeC2221f9f4D8dfbAFa6B9a297d17603493D", 18],
      "USDC": ["0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",6],
      "AERO": ["0x940181a94A35A4569E4529A3CDfB74e38FD98631",18],
      "OVN": ["0xA3d1a8DEB97B111454B294E2324EfAD13a9d8396",18],
      "USD+": ["0xB79DD08EA68A908A97220C76d19A6aA9cBDE4376",18],
      "JARVIS": ["0x777b2839832982B35213063d850848369390EE16",18],
      "TKN": ["0x78b3C724A2F663D11373C4a1978689271895256f",18],
      "MET": ["0x93dc5Cb35627A759848c7A7F0079EA7b49E435a5",18],
      "WELS": ["0x7F62ac1e974D65Fab4A81821CA6AF659A5F46298",18],
      "fBOMB": ["0x74ccbe53F77b08632ce0CB91D3A545bF6B8E0979",18],
      "wstETH": ["0xc1CBa3fCea344f92D9239c08C0568f6F2F0ee452",18],
      "BAVA": ["0x3fbdE9864362CE4Abb244EbeF2EF0482ABA8eA39",18],
      "KLIMA": ["0xDCEFd8C8fCc492630B943ABcaB3429F12Ea9Fea2",18],
      "SAM": ["0xed1779845520339693CDBffec49a74246E7D671b",18],
      "BET": ["0x80F6BcedD3d4fa1035285affA30e38f464Db3895",18],
      "OX": ["0xba0Dda8762C24dA9487f5FA026a9B64b695A07Ea",18],
      "BCT": ["0x576Bca23DcB6d94fF8E537D88b0d3E1bEaD444a2",18],
      "RFL": ["0x6e2c81b6c2C0e02360F00a0dA694e489acB0b05e",18],
      "ION": ["0x3eE5e23eEE121094f1cFc0Ccc79d6C809Ebd22e5",18],
      "PDT": ["0xeff2A458E464b07088bDB441C21A42AB4b61e07E",18],
      "RECORD": ["0xE642657E4F43e6DcF0bd73Ef24008394574Dee28",18],
      "LRDS": ["0xB676f87A6E701f0DE8De5Ab91B56b66109766DB1",18],
      "cbBTC": ["0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf",18],
      "VIRTUAL": ["0x0b3e328455c4059EEb9e3f84b5543F74E24e7E1b",18],
      "BOTTO": ["0x24914CB6BD01E6a0CF2a9c0478e33c25926e6a0c",18],
      "ICL": ["0x12c26138b666360AB2B7A1B149dF9Cf6642CDfBf",18],
      "HBR": ["0x416bd43Bc76D496ff49923c20eCaf86b52Ab078d",18],
      "USDbC": ["0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",6],
      "OGN": ['0x7002458B1DF59EccB57387bC79fFc7C29E22e6f7', 18],
      "superOETHb": ['0xDBFeFD2e8460a6Ee4955A68582F85708BAEA60A3', 18],

      "DOLA": ['0x4621b7A9c75199271F773Ebd9A499dbd165c3191', 18],
      "USDz": ['0x04D5ddf5f3a8939889F11E97f8c4BB48317F1938', 18],
      "msETH": ['0x7Ba6F01772924a82D9626c126347A28299E98c98', 18],
      "MAI": ['0xbf1aeA8670D2528E08334083616dD9C5F3B087aE', 18],
      "msUSD": ['0x526728DBc96689597F85ae4cd716d4f7fCcBAE9d', 18],


      TOMB: ['0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7', 18],
      TBOND: ['0x24248CD1747348bDC971a5395f4b3cd7feE94ea0', 18],
      TSHARE: ['0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37', 18],
      FUSDT: ['0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', 6], // This is actually usdc on mainnet not fusdt
      BOO: ['0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE', 18],
      ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
      SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
      LQDR: ['0x10b620b2dbAC4Faa7D7FFD71Da486f5D44cd86f9', 18],
      fUSDT: ['0x049d68029688eAbF473097a2fC38ef61633A3C7A', 6],
      BTC: ['0x321162Cd933E2Be498Cd2267a90534A804051b11', 8],
      FRAX: ['0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355', 18],
      'CRE8R': ['0x2aD402655243203fcfa7dCB62F8A08cc2BA88ae0', 18],
      'USDT-FTM-LP': ['0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D', 18],

      'ETH-OBELISK-LP': ['0x5D3fA526f1F984061D9641472cdA29eC9872E28b', 18],
      'ETH-GOBELISK-LP': ['0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D', 18], // missing
      "ETH-TAROT-LP": ["0x2d25E0514f23c6367687dE89Bd5167dc754D4934",18],
      "ETH-WELL-LP": ["0xffA3F8737C39e36dec4300B162c2153c67c8352f",18],
      "ETH-USDC-LP": ["0xcDAC0d6c6C59727a65F871236188350531885C43",18],
      "USDC-AERO-LP": ["0x6cDcb1C4A4D1C3C6d054b27AC5B77e89eAFb971d",18],
      "OVN-USD+-LP": ["0x61366A4e6b1DB1b85DD701f2f4BFa275EF271197",18],
      "AERO-TAROT-LP": ["0x7810F8f3253ced11e8c56C6C8497f3134055D44C",18],
      "ETH-JARVIS-LP": ["0xeff0891e5a1bd47a8F1e77ABebfEE4145974D088",18],
      "ETH-TKN-LP": ["0x1a9f461a371559f82976fa18C46A6A0d29f131d0",18],
      "ETH-MET-LP": ["0xF91E0Dfe1265B914182De54E08C9CA2068bedDDE",18],
      "ETH-WELS-LP": ["0xCaedc2561356B6a01a94E3C0438011459E91FBb9",18],
      "fBOMB-wstETH-LP": ["0xBd1F3d188de7eE07B1b323C0D26D6720CAfB8780",18],
      "TKN-AERO-LP": ["0xd88b9f7185f404015B665e070A5EF03a7e26661F",18],
      "BAVA-AERO-LP": ["0xD3c2fa602B8da5b0b9c25dFBE7F2f31Bb512dB13",18],
      "ETH-KLIMA-LP": ["0xB37642E87613d8569Fd8Ec80888eA6c63684E79e",18],
      "USDC-KLIMA-LP": ["0x958682eC6282BC7E939FA8Ba9397805C214c3A09",18],
      "ETH-SAM-LP": ["0x598299Fb3f3829F7Ba08662948706cDFf7eC2350",18],
      "BET-USDC-LP": ["0xF471D220F16Cc9c3400B5BD97d728072A87b9C75",18],
      "ETH-OX-LP": ["0xe140c5930889c4064A3d11b17Bb04fE7224a5CDC",18],
      "BCT-USDC-LP": ["0x77e09a5043820820390904357463BfB739a76104",18],
      "ETH-RFL-LP": ["0x37785d5bE19D0d9559D4634a5385c72c419b25Da",18],
      "ION-ETH-LP": ["0x0FAc819628a7F612AbAc1CaD939768058cc0170c",18],
      "ETH-PDT-LP": ["0x04e5A1c883Dafd1eAE6b11BD6d3Eb784D90ce515",18],
      "ETH-RECORD-LP": ["0x0AA3E62f4d97C404012352E881a2D0f2712c24A2",18],
      "ETH-LRDS-LP": ["0x14f7Ce67e16c435C876AFF4092eb547584121312",18],
      "USDC-cbBTC-LP": ["0x9c38b55f9A9Aba91BbCEDEb12bf4428f47A6a0B8",18],
      "VIRTUAL-cbBTC-LP": ["0xb909F567c5c2Bb1A4271349708CC4637D7318b4A",18],
      "fBOMB-cbBTC-LP": ["0x3d36Cf85a0c275cF4894315a6c1D6C558D6d984C",18],
      "ETH-cbBTC-LP": ["0x2578365B3dfA7FfE60108e181EFb79FeDdec2319",18],
      "fBOMB-WELS-LP": ["0x151f69bAD003e0788c13990909b281e0a813d702",18],
      "BOTTO-ETH-LP": ["0x55F618171d851d57906431ce9FEEe96Dc6f3877e",18],
      "cbBTC-TAROT-LP": ["0x2E26a29f782e51296d9D31f78D4883462a2d339d",18],
      "BAVA-USDC-LP": ["0x2215Ae205C5F28af4103e77478D65AF1044559F6",18],
      "ICL-ETH-LP": ["0xf8128cB2d1e3Eb8598BF8c5B4935893f92d9ea28",18],
      "HBR-USDbC-LP": ["0xf03794773236E3A4d2E773d6CE84F5a10E2E957c",18],
      "ETH-AERO-LP": ["0x7f670f78B17dEC44d5Ef68a48740b6f8849cc2e6",18],
      "DOLA-USDC-LP": ["0xf213F2D02837012dC0236cC105061e121bB03e37",18],
      "USDz-USDC-LP": ["0x6d0b9C9E92a3De30081563c3657B5258b3fFa38B",18],
      "ETH-msETH-LP": ["0xDE4FB30cCC2f1210FcE2c8aD66410C586C8D1f9A",18],
      "USDC-MAI-LP": ["0x2a1463CeBE85315224c536AfD389b381B43F3206",18],
      "msUSD-USDC-LP": ["0xcEFC8B799a8EE5D9b312aeca73262645D664AaF7",18],
      // 'OGN-superOETHb-LP': ['0x6FB655476fDCFB9712dD200308D941A1c6D1119e', 18],

      'TOMB-FTM-LP': ['0x2A651563C9d3Af67aE0388a5c8F89b867038089e', 18],
      'TSHARE-FTM-LP': ['0x4733bc45eF91cF7CcEcaeeDb794727075fB209F2', 18],
      'LQDR-FTM-LP': ['0x4Fe6f19031239F105F753D1DF8A0d24857D0cAA2', 18],
      'CRE8R-FTM-LP': ['0x459e7c947E04d73687e786E4A48815005dFBd49A', 18],
      'fUSDT-FTM-LP': ['0xd14Dd3c56D9bc306322d4cEa0E1C49e9dDf045D4', 18],
      'USDC-FTM-LP': ['0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D', 18],
      'BTC-FTM-LP': ['0x279b2c897737a50405ED2091694F225D83F2D3bA', 18],
      'ETH-FTM-LP': ['0x613BF4E46b4817015c01c6Bb31C7ae9edAadc26e', 18],
      'FRAX-FTM-LP': ['0x7ed0cdDB9BB6c6dfEa6fB63E117c8305479B8D7D', 18],
      'MAI-FTM-LP': ['0x51Eb93ECfEFFbB2f6fE6106c4491B5a0B944E8bd', 18],
      'axlUSDC': ['0x1B6382DBDEa11d97f24495C9A90b7c88469134a4', 6],

      BOAT: ['0x9117580772aD7e77eb533847A8bb11303Fd1955B', 18],
      BOMB: ['0x8503eb4A136bDBeB323E37Aa6e0FA0C772228378', 18],
      BIFI: ['0xd6070ae98b8069de6B494332d1A1a81B6179D960', 18],
      DAI: ['0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E', 18],
      DEI: ['0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3', 18],
      EAGLE: ['0xeb3bA75334A58cB80C45dfBb551f03A5FdE452E6', 18],
      BASED: ['0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae', 18],
      gOHM: ['0x91fa20244Fb509e8289CA630E5db3E9166233FDc', 18],
      ATLAS: ['0x92df3eaBf7c1c2A6b3D5793f6d53778eA78c48b2', 18],
      DEUS: ['0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44', 18],
      'BOMB-FTM-LP': ['0x11d7fD8Deb7F37EA97218F70550E03fe6683df3D', 18],
      'BIFI-FTM-LP': ['0xc28cf9aeBfe1A07A27B3A4d722C841310e504Fe3', 18],
      'DEI-USDC-LP': ['0x8eFD36aA4Afa9F4E157bec759F1744A7FeBaEA0e', 18],
      'gOHM-FTM-LP': ['0xae9BBa22E87866e48ccAcFf0689AFaa41eB94995', 18],
      'ATLAS-FTM-LP': ['0xFFcF183126dF14EC4E59409bAb431885ccEEb1C2', 18],
      'DEUS-FTM-LP': ['0x2599Eba5fD1e49F294C76D034557948034d6C96E', 18],

      '0x12FC98B8545fb5BA4824Ef948a5e5423190363a8': ['0x12FC98B8545fb5BA4824Ef948a5e5423190363a8', 18],
      '0x8DD4D2eb0693924B98BFEaE39e5C3cE5B0e12c84': ['0x8DD4D2eb0693924B98BFEaE39e5C3cE5B0e12c84', 18],
      '0x61BDB074c39a227f9f79588C1e3Cc904f38b9C19': ['0x61BDB074c39a227f9f79588C1e3Cc904f38b9C19', 18],
      '0x8792DB08e2075b49AA1c7269da1CB1117C24F815': ['0x8792DB08e2075b49AA1c7269da1CB1117C24F815', 18],
      '0x798924bcE0f5380a85706ddb70C59F59217f375e': ['0x798924bcE0f5380a85706ddb70C59F59217f375e', 18],
      '0xfFC07E2Bd4378756c96ba5F915cB731eFB052D48': ['0xfFC07E2Bd4378756c96ba5F915cB731eFB052D48', 18],
      /* NEW */
      '0xA0E52d51C83A46432a18eB78B643A0bA2b34fbe6': ['0xA0E52d51C83A46432a18eB78B643A0bA2b34fbe6', 18],
      '0x8bf7472BDcE91035E95Cca95cB51FEeaAdC52AE7': ['0x8bf7472BDcE91035E95Cca95cB51FEeaAdC52AE7', 18],
      '0x63fB0BC4a727F40Cdb41571B0D6254866fd36C67': ['0x63fB0BC4a727F40Cdb41571B0D6254866fd36C67', 18],
      '0x24F0352Bab77f73E99cd5253a6771FDeD90c00fF': ['0x24F0352Bab77f73E99cd5253a6771FDeD90c00fF', 18],
      '0x6f2407057dae200E688384eC372F169A01Ed7E70': ['0x6f2407057dae200E688384eC372F169A01Ed7E70', 18],
      '0x6896352948619132c271C3B302d8DD89F61DB8b2': ['0x6896352948619132c271C3B302d8DD89F61DB8b2', 18],
      '0xcae8b5ada97c51860fabbb323736c49ecc638cfc': ['0xcae8b5ada97c51860fabbb323736c49ecc638cfc', 18],
      '0xb8Cec5c57F1D6004ADC48DdDfa848ce8E164EFfB': ['0xb8Cec5c57F1D6004ADC48DdDfa848ce8E164EFfB', 18],
      '0x6ABd7c99E0Fc531CaEd7Fc28da6940c07545200D': ['0x6ABd7c99E0Fc531CaEd7Fc28da6940c07545200D', 18],
      '0x6185B40CB5D35a1346Fb12dA28F8Cf5e81b9dE49': ['0x6185B40CB5D35a1346Fb12dA28F8Cf5e81b9dE49', 18],
      '0x63F4138b5c5BD7E8Aa5B2e99efb01868f0FFa431': ['0x63F4138b5c5BD7E8Aa5B2e99efb01868f0FFa431', 18],
      '0xB47AE093BE781Cc5C5e3525207785A4FA8cB989c': ['0xB47AE093BE781Cc5C5e3525207785A4FA8cB989c', 18],
      '0xd598D195e77E595d70270DCCB33f6AB3c7b3B4b8': ['0xd598D195e77E595d70270DCCB33f6AB3c7b3B4b8', 18],
      '0x9D7DECe83405D5F41F970Fc5197C1732962960B9': ['0x9D7DECe83405D5F41F970Fc5197C1732962960B9', 18],
      '0xEbcf7659E41cFa1B80aA2Bebf06Aea245E0124f4': ['0xEbcf7659E41cFa1B80aA2Bebf06Aea245E0124f4', 18],
      '0xaA68e97bab8bCFea72E1371496B804386F930f79': ['0xaA68e97bab8bCFea72E1371496B804386F930f79', 18],

      '0x49E8619991f9D52c16D71E0505eEB8Bc9C6dda04': ['0x49E8619991f9D52c16D71E0505eEB8Bc9C6dda04', 18],
      '0x374EF9BC22BB718fA0a8A53230c5e2aE16c722e0': ['0x374EF9BC22BB718fA0a8A53230c5e2aE16c722e0', 18],
      '0x0301a27483E92EbDfAE00b173A48b8eC8568D4fe': ['0x0301a27483E92EbDfAE00b173A48b8eC8568D4fe', 18],
      '0x8C1BbD5AabA754c8715eB039966b0E9dFa564368': ['0x8C1BbD5AabA754c8715eB039966b0E9dFa564368', 18],
      '0x42D3e161319bd5b014c6b7f90fc34D2324456569': ['0x42D3e161319bd5b014c6b7f90fc34D2324456569', 18],
      '0xA2519B723c4c2Eee6faB3bf3074c551b61b30E73': ['0xA2519B723c4c2Eee6faB3bf3074c551b61b30E73', 18],
      '0x2Da99d58553BF60fE5bD755Ea3AC3cAA8582578E': ['0x2Da99d58553BF60fE5bD755Ea3AC3cAA8582578E', 18],
      '0x714f1b9b581826aa87e885e366d5315d6a676102': ['0x714f1b9b581826aa87e885e366d5315d6a676102', 18],
      '0x500445604Ceac98a5E889B064548BfBE0407Bf8a': ['0x500445604Ceac98a5E889B064548BfBE0407Bf8a', 18],
      '0x4d0Cb4A33663589b4003FbDc83d8cA87D9C507A5': ['0x4d0Cb4A33663589b4003FbDc83d8cA87D9C507A5', 18],
      '0x46A551E76e8E9e46D7983707925CF509A07bbDdC': ['0x46A551E76e8E9e46D7983707925CF509A07bbDdC', 18],
      '0xc5f901A80217A82D449d98012270528378E3B723': ['0xc5f901A80217A82D449d98012270528378E3B723', 18],
      '0xF9200499fF5DAA82aEFe1541F764abcB00d68FEB': ['0xF9200499fF5DAA82aEFe1541F764abcB00d68FEB', 18],
      '0x0b84df4e6974099a3eaf678f4379cb12d5c84f64': ['0x0b84df4e6974099a3eaf678f4379cb12d5c84f64', 18],
    },
    baseLaunchDate: new Date('2024-11-23T18:00:00Z'),
    bondLaunchesAt: new Date('2024-12-07T20:00:00Z'),
    masonryLaunchesAt: new Date('2024-12-07T21:00:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding OBELISK
        - 2 = LP asset staking rewarding GOBELISK
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  "AerodromeETHOBELISKRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-OBELISK LP",
    "poolId": 0,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-OBELISK-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "OBELISK",
    "token2Name": "ETH",
    "finished": false,
    "sort": 0,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHTAROTRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-TAROT LP",
    "poolId": 1,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-TAROT-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "TAROT",
    "finished": false,
    "sort": 1,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHWELLRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-WELL LP",
    "poolId": 2,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-WELL-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "WELL",
    "finished": false,
    "sort": 2,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHUSDCRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-USDC LP",
    "poolId": 3,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-USDC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "USDC",
    "finished": false,
    "sort": 3,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeUSDCAERORewardPoolGenesis": {
    "name": "Earn OBELISK by USDC-AERO LP",
    "poolId": 4,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "USDC-AERO-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "USDC",
    "token2Name": "AERO",
    "finished": false,
    "sort": 4,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeOVNUSD+RewardPoolGenesis": {
    "name": "Earn OBELISK by OVN-USD+ LP",
    "poolId": 5,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "OVN-USD+-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "OVN",
    "token2Name": "USD+",
    "finished": false,
    "sort": 5,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeAEROTAROTRewardPoolGenesis": {
    "name": "Earn OBELISK by AERO-TAROT LP",
    "poolId": 6,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "AERO-TAROT-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "AERO",
    "token2Name": "TAROT",
    "finished": false,
    "sort": 6,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHJARVISRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-JARVIS LP",
    "poolId": 7,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-JARVIS-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "JARVIS",
    "finished": false,
    "sort": 7,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHTKNRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-TKN LP",
    "poolId": 8,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-TKN-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "TKN",
    "finished": false,
    "sort": 8,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHMETRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-MET LP",
    "poolId": 9,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-MET-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "MET",
    "finished": false,
    "sort": 9,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHWELSRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-WELS LP",
    "poolId": 10,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-WELS-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "WELS",
    "finished": false,
    "sort": 10,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromefBOMBwstETHRewardPoolGenesis": {
    "name": "Earn OBELISK by fBOMB-wstETH LP",
    "poolId": 11,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "fBOMB-wstETH-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "fBOMB",
    "token2Name": "wstETH",
    "finished": false,
    "sort": 11,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeTKNAERORewardPoolGenesis": {
    "name": "Earn OBELISK by TKN-AERO LP",
    "poolId": 12,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "TKN-AERO-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "TKN",
    "token2Name": "AERO",
    "finished": false,
    "sort": 12,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeBAVAAERORewardPoolGenesis": {
    "name": "Earn OBELISK by BAVA-AERO LP",
    "poolId": 13,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "BAVA-AERO-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "BAVA",
    "token2Name": "AERO",
    "finished": false,
    "sort": 13,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHKLIMARewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-KLIMA LP",
    "poolId": 14,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-KLIMA-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "KLIMA",
    "finished": false,
    "sort": 14,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeUSDCKLIMARewardPoolGenesis": {
    "name": "Earn OBELISK by USDC-KLIMA LP",
    "poolId": 15,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "USDC-KLIMA-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "USDC",
    "token2Name": "KLIMA",
    "finished": false,
    "sort": 15,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHSAMRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-SAM LP",
    "poolId": 16,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-SAM-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "SAM",
    "finished": false,
    "sort": 16,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeBETUSDCRewardPoolGenesis": {
    "name": "Earn OBELISK by BET-USDC LP",
    "poolId": 17,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "BET-USDC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "BET",
    "token2Name": "USDC",
    "finished": false,
    "sort": 17,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHOXRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-OX LP",
    "poolId": 18,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-OX-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "OX",
    "finished": false,
    "sort": 18,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeBCTUSDCRewardPoolGenesis": {
    "name": "Earn OBELISK by BCT-USDC LP",
    "poolId": 19,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "BCT-USDC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "BCT",
    "token2Name": "USDC",
    "finished": false,
    "sort": 19,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHRFLRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-RFL LP",
    "poolId": 20,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-RFL-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "RFL",
    "finished": false,
    "sort": 20,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeIONETHRewardPoolGenesis": {
    "name": "Earn OBELISK by ION-ETH LP",
    "poolId": 21,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ION-ETH-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ION",
    "token2Name": "ETH",
    "finished": false,
    "sort": 21,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHPDTRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-PDT LP",
    "poolId": 22,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-PDT-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "PDT",
    "finished": false,
    "sort": 22,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHRECORDRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-RECORD LP",
    "poolId": 23,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-RECORD-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "RECORD",
    "finished": false,
    "sort": 23,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHLRDSRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-LRDS LP",
    "poolId": 24,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-LRDS-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "LRDS",
    "finished": false,
    "sort": 24,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeUSDCcbBTCRewardPoolGenesis": {
    "name": "Earn OBELISK by USDC-cbBTC LP",
    "poolId": 25,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "USDC-cbBTC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "USDC",
    "token2Name": "cbBTC",
    "finished": false,
    "sort": 25,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeVIRTUALcbBTCRewardPoolGenesis": {
    "name": "Earn OBELISK by VIRTUAL-cbBTC LP",
    "poolId": 26,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "VIRTUAL-cbBTC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "VIRTUAL",
    "token2Name": "cbBTC",
    "finished": false,
    "sort": 26,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromefBOMBcbBTCRewardPoolGenesis": {
    "name": "Earn OBELISK by fBOMB-cbBTC LP",
    "poolId": 27,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "fBOMB-cbBTC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "fBOMB",
    "token2Name": "cbBTC",
    "finished": false,
    "sort": 27,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHcbBTCRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-cbBTC LP",
    "poolId": 28,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-cbBTC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "cbBTC",
    "finished": false,
    "sort": 28,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromefBOMBWELSRewardPoolGenesis": {
    "name": "Earn OBELISK by fBOMB-WELS LP",
    "poolId": 29,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "fBOMB-WELS-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "fBOMB",
    "token2Name": "WELS",
    "finished": false,
    "sort": 29,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeBOTTOETHRewardPoolGenesis": {
    "name": "Earn OBELISK by BOTTO-ETH LP",
    "poolId": 30,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "BOTTO-ETH-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "BOTTO",
    "token2Name": "ETH",
    "finished": false,
    "sort": 30,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromecbBTCTAROTRewardPoolGenesis": {
    "name": "Earn OBELISK by cbBTC-TAROT LP",
    "poolId": 31,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "cbBTC-TAROT-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "cbBTC",
    "token2Name": "TAROT",
    "finished": false,
    "sort": 31,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeBAVAUSDCRewardPoolGenesis": {
    "name": "Earn OBELISK by BAVA-USDC LP",
    "poolId": 32,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "BAVA-USDC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "BAVA",
    "token2Name": "USDC",
    "finished": false,
    "sort": 32,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeICLETHRewardPoolGenesis": {
    "name": "Earn OBELISK by ICL-ETH LP",
    "poolId": 33,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ICL-ETH-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ICL",
    "token2Name": "ETH",
    "finished": false,
    "sort": 33,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeHBRUSDbCRewardPoolGenesis": {
    "name": "Earn OBELISK by HBR-USDbC LP",
    "poolId": 34,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "HBR-USDbC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "HBR",
    "token2Name": "USDbC",
    "finished": false,
    "sort": 34,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeETHAERORewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-AERO LP",
    "poolId": 35,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-AERO-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "AERO",
    "finished": false,
    "sort": 35,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ]
  },
  "AerodromeDOLAUSDCRewardPoolGenesis": {
    "name": "Earn OBELISK by DOLA-USDC LP",
    "poolId": 36,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "DOLA-USDC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "DOLA",
    "token2Name": "USDC",
    "finished": false,
    "sort": 36,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ],
    "stableAeroPool": true,
  },
  "AerodromeUSDzUSDCRewardPoolGenesis": {
    "name": "Earn OBELISK by USDz-USDC LP",
    "poolId": 37,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "USDz-USDC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "USDz",
    "token2Name": "USDC",
    "finished": false,
    "sort": 37,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ],
    "stableAeroPool": true,
  },
  "AerodromeETHmsETHRewardPoolGenesis": {
    "name": "Earn OBELISK by ETH-msETH LP",
    "poolId": 38,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "ETH-msETH-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "ETH",
    "token2Name": "msETH",
    "finished": false,
    "sort": 38,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ],
    "stableAeroPool": true,
  },
  "AerodromeUSDCMAIRewardPoolGenesis": {
    "name": "Earn OBELISK by USDC-MAI LP",
    "poolId": 39,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "USDC-MAI-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "USDC",
    "token2Name": "MAI",
    "finished": false,
    "sort": 39,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ],
    "stableAeroPool": true,
  },
  "AerodromemsUSDUSDCRewardPoolGenesis": {
    "name": "Earn OBELISK by msUSD-USDC LP",
    "poolId": 40,
    "sectionInUI": 0,
    "contract": "ObeliskGenesisRewardPool",
    "depositTokenName": "msUSD-USDC-LP",
    "earnTokenName": "OBELISK",
    "token1Name": "msUSD",
    "token2Name": "USDC",
    "finished": false,
    "sort": 40,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "OBELISK"
    ],
    "stableAeroPool": true,
  },

  /* NORMAL FARMS */
  "AerodromeETHTAROTRewardPool": {
    "name": "Earn GOBELISK by ETH-TAROT LP",
    "poolId": 0,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-TAROT-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "TAROT",
    "finished": false,
    "sort": 0,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHWELLRewardPool": {
    "name": "Earn GOBELISK by ETH-WELL LP",
    "poolId": 11,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-WELL-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "WELL",
    "finished": false,
    "sort": 11,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHUSDCRewardPool": {
    "name": "Earn GOBELISK by ETH-USDC LP",
    "poolId": 2,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-USDC-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "USDC",
    "finished": false,
    "sort": 2,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeUSDCAERORewardPool": {
    "name": "Earn GOBELISK by USDC-AERO LP",
    "poolId": 3,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "USDC-AERO-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "USDC",
    "token2Name": "AERO",
    "finished": false,
    "sort": 3,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeOVNUSD+RewardPool": {
    "name": "Earn GOBELISK by OVN-USD+ LP",
    "poolId": 4,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "OVN-USD+-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "OVN",
    "token2Name": "USD+",
    "finished": false,
    "sort": 4,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeAEROTAROTRewardPool": {
    "name": "Earn GOBELISK by AERO-TAROT LP",
    "poolId": 5,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "AERO-TAROT-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "AERO",
    "token2Name": "TAROT",
    "finished": false,
    "sort": 5,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHJARVISRewardPool": {
    "name": "Earn GOBELISK by ETH-JARVIS LP",
    "poolId": 6,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-JARVIS-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "JARVIS",
    "finished": false,
    "sort": 6,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHTKNRewardPool": {
    "name": "Earn GOBELISK by ETH-TKN LP",
    "poolId": 7,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-TKN-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "TKN",
    "finished": false,
    "sort": 7,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHMETRewardPool": {
    "name": "Earn GOBELISK by ETH-MET LP",
    "poolId": 8,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-MET-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "MET",
    "finished": false,
    "sort": 8,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHWELSRewardPool": {
    "name": "Earn GOBELISK by ETH-WELS LP",
    "poolId": 9,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-WELS-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "WELS",
    "finished": false,
    "sort": 9,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromefBOMBwstETHRewardPool": {
    "name": "Earn GOBELISK by fBOMB-wstETH LP",
    "poolId": 10,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "fBOMB-wstETH-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "fBOMB",
    "token2Name": "wstETH",
    "finished": false,
    "sort": 10,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeTKNAERORewardPool": {
    "name": "Earn GOBELISK by TKN-AERO LP",
    "poolId": 12,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "TKN-AERO-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "TKN",
    "token2Name": "AERO",
    "finished": false,
    "sort": 12,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeBAVAAERORewardPool": {
    "name": "Earn GOBELISK by BAVA-AERO LP",
    "poolId": 13,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "BAVA-AERO-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "BAVA",
    "token2Name": "AERO",
    "finished": false,
    "sort": 13,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHKLIMARewardPool": {
    "name": "Earn GOBELISK by ETH-KLIMA LP",
    "poolId": 14,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-KLIMA-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "KLIMA",
    "finished": false,
    "sort": 14,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeUSDCKLIMARewardPool": {
    "name": "Earn GOBELISK by USDC-KLIMA LP",
    "poolId": 15,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "USDC-KLIMA-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "USDC",
    "token2Name": "KLIMA",
    "finished": false,
    "sort": 15,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHSAMRewardPool": {
    "name": "Earn GOBELISK by ETH-SAM LP",
    "poolId": 16,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-SAM-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "SAM",
    "finished": false,
    "sort": 16,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeBETUSDCRewardPool": {
    "name": "Earn GOBELISK by BET-USDC LP",
    "poolId": 17,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "BET-USDC-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "BET",
    "token2Name": "USDC",
    "finished": false,
    "sort": 17,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHOXRewardPool": {
    "name": "Earn GOBELISK by ETH-OX LP",
    "poolId": 18,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-OX-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "OX",
    "finished": false,
    "sort": 18,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeBCTUSDCRewardPool": {
    "name": "Earn GOBELISK by BCT-USDC LP",
    "poolId": 19,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "BCT-USDC-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "BCT",
    "token2Name": "USDC",
    "finished": false,
    "sort": 19,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHRFLRewardPool": {
    "name": "Earn GOBELISK by ETH-RFL LP",
    "poolId": 20,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-RFL-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "RFL",
    "finished": false,
    "sort": 20,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeIONETHRewardPool": {
    "name": "Earn GOBELISK by ION-ETH LP",
    "poolId": 21,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ION-ETH-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ION",
    "token2Name": "ETH",
    "finished": false,
    "sort": 21,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHPDTRewardPool": {
    "name": "Earn GOBELISK by ETH-PDT LP",
    "poolId": 22,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-PDT-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "PDT",
    "finished": false,
    "sort": 22,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHRECORDRewardPool": {
    "name": "Earn GOBELISK by ETH-RECORD LP",
    "poolId": 23,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-RECORD-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "RECORD",
    "finished": false,
    "sort": 23,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHLRDSRewardPool": {
    "name": "Earn GOBELISK by ETH-LRDS LP",
    "poolId": 24,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-LRDS-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "LRDS",
    "finished": false,
    "sort": 24,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeUSDCcbBTCRewardPool": {
    "name": "Earn GOBELISK by USDC-cbBTC LP",
    "poolId": 25,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "USDC-cbBTC-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "USDC",
    "token2Name": "cbBTC",
    "finished": false,
    "sort": 25,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeVIRTUALcbBTCRewardPool": {
    "name": "Earn GOBELISK by VIRTUAL-cbBTC LP",
    "poolId": 26,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "VIRTUAL-cbBTC-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "VIRTUAL",
    "token2Name": "cbBTC",
    "finished": false,
    "sort": 26,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromefBOMBcbBTCRewardPool": {
    "name": "Earn GOBELISK by fBOMB-cbBTC LP",
    "poolId": 27,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "fBOMB-cbBTC-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "fBOMB",
    "token2Name": "cbBTC",
    "finished": false,
    "sort": 27,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeETHcbBTCRewardPool": {
    "name": "Earn GOBELISK by ETH-cbBTC LP",
    "poolId": 28,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ETH-cbBTC-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ETH",
    "token2Name": "cbBTC",
    "finished": false,
    "sort": 28,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromefBOMBWELSRewardPool": {
    "name": "Earn GOBELISK by fBOMB-WELS LP",
    "poolId": 29,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "fBOMB-WELS-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "fBOMB",
    "token2Name": "WELS",
    "finished": false,
    "sort": 29,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeBOTTOETHRewardPool": {
    "name": "Earn GOBELISK by BOTTO-ETH LP",
    "poolId": 30,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "BOTTO-ETH-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "BOTTO",
    "token2Name": "ETH",
    "finished": false,
    "sort": 30,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromecbBTCTAROTRewardPool": {
    "name": "Earn GOBELISK by cbBTC-TAROT LP",
    "poolId": 31,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "cbBTC-TAROT-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "cbBTC",
    "token2Name": "TAROT",
    "finished": false,
    "sort": 31,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeBAVAUSDCRewardPool": {
    "name": "Earn GOBELISK by BAVA-USDC LP",
    "poolId": 32,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "BAVA-USDC-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "BAVA",
    "token2Name": "USDC",
    "finished": false,
    "sort": 32,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeICLETHRewardPool": {
    "name": "Earn GOBELISK by ICL-ETH LP",
    "poolId": 33,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "ICL-ETH-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "ICL",
    "token2Name": "ETH",
    "finished": false,
    "sort": 33,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },
  "AerodromeHBRUSDbCRewardPool": {
    "name": "Earn GOBELISK by HBR-USDbC LP",
    "poolId": 34,
    "sectionInUI": 2,
    "contract": "CemeteryGobeliskRewardPool",
    "depositTokenName": "HBR-USDbC-LP",
    "earnTokenName": "GOBELISK",
    "token1Name": "HBR",
    "token2Name": "USDbC",
    "finished": false,
    "sort": 34,
    "closedForStaking": false,
    "depositTokenFrom": "AERODROME",
    "isGenesis": false,
    "depositFee": "0",
    "allocPoint": 0,
    "rewardTokens": [
      "GOBELISK"
    ]
  },

  // "AerodromeOgnSuperOethbRewardPool": {
  //   name: 'Earn superOETHb by OGN-superOETHb LP',
  //   poolId: 35,
  //   sectionInUI: 2,
  //   contract: 'CemeteryGobeliskRewardPool',
  //   depositTokenName: 'OGN-superOETHb-LP',
  //   earnTokenName: 'GOBELISK',
  //   token1Name: "OGN",
  //   token2Name: "superOETHb",
  //   finished: false,
  //   sort: 33,
  //   closedForStaking: false,
  //   depositTokenFrom: "AERODROME",
  //   isGenesis: false,
  //   depositFee: "0",
  //   allocPoint: 207000,
  //   rewardTokens: ['GOBELISK'],
  // },
};

export default configurations['development'];
// export default configurations[process.env.NODE_ENV || 'development'];
