export const OBELISK_TICKER = 'OBELISK';
export const GOBELISK_TICKER = 'GOBELISK';
export const BOBELISK_TICKER = 'BOBELISK';
export const ETH_TICKER = 'ETH';
export const WETH_TICKER = 'WETH';
export const SPIRIT_ROUTER_ADDR = '0xF491e7B69E4244ad4002BC14e878a34207E38c29';
export const ZAPPER_ROUTER_ADDR = '0x0aEe64C8db494A153038B09De11cB0dddAEdEE5d';
export const TAX_OFFICE_ADDR = '0x71394320F2A5Fcb37A1E2916e589d5B61469c905';

// do a export function that checks if im on localhost or not
export function isLocalhost() {
    return window.location.hostname === "localhost"
}

// do other export function to get the thegraph url based on isLocalhost
export function getBaseApiUrl() {
    const forceProd = true
    if (forceProd) {
        return "https://oldapi.obelisk.finance"
    }

    return isLocalhost() ? "http://localhost:5000" : "https://oldapi.obelisk.finance"
}