import { Fetcher as FetcherSpirit, Route, Token as TokenSpirit } from '@spiritswap/sdk';
import { Fetcher as FetcherSpooky, Route as RouteSpooky, Token as TokenSpooky /*, Route as RouteSpooky*/ } from '@spookyswap/sdk'
import { Configuration } from './config';
import { ContractName, TokenStat, AllocationTime, LPStat, Bank, PoolStats, TShareSwapperStat, AccountInfo } from './types';
import { BigNumber, Contract, ethers, EventFilter } from 'ethers';
import { decimalToBalance } from './ether-utils';
import { TransactionResponse } from '@ethersproject/providers';
import ERC20 from './ERC20';
import { getFullDisplayBalance, getDisplayBalance } from '../utils/formatBalance';
import { getDefaultProvider } from '../utils/provider';
import IUniswapV2PairABI from './IUniswapV2Pair.abi.json';
import config, { bankDefinitions } from '../config';
import moment from 'moment';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { ETH_TICKER, SPIRIT_ROUTER_ADDR, OBELISK_TICKER, getBaseApiUrl } from '../utils/constants';
/**
 * An API module of Obelisk Finance contracts.
 * All contract-interacting domain logic should be defined in here.
 */
export class TombFinance {
    myAccount: string;
    provider: ethers.providers.Web3Provider;
    signer?: ethers.Signer;
    config: Configuration;
    contracts: { [name: string]: Contract };
    externalTokens: { [name: string]: ERC20 };
    masonryVersionOfUser?: string;

    TOMBFTMLP: Contract;
    TSHAREFTMLP: Contract;
    LQDRFTMLP: Contract;
    CREATORFTMLP: Contract;
    fUSDTFTMLP: Contract;
    USDCFTMLP: Contract;
    BTCFTMLP: Contract;
    ETHFTMLP: Contract;
    FRAXFTMLP: Contract;
    MAIFTMLP: Contract;
    BOMBFTMLP: Contract;
    BIFIFTMLP: Contract;
    DEIUSDCLP: Contract;
    gOHMFTMLP: Contract;
    ATLASFTMLP: Contract;
    OBELISKETHLP: Contract;
    GOBELISKETHLP: Contract;
    DEUSFTMLP: Contract;

    ETHAERO_LP: Contract;
    DOLAUSDC_LP: Contract;
    USDzUSDC_LP: Contract;
    ETHmsETH_LP: Contract;
    USDCMAI_LP: Contract;
    msUSDUSDC_LP: Contract;
    ETHLRDS_LP: Contract;
    HBRUSDbC_LP: Contract;
    VIRTUALcbBTC_LP: Contract;
    ETHOX_LP: Contract;
    ETHRECORD_LP: Contract;
    ETHRFL_LP: Contract;
    ETHION_LP: Contract;
    ETHSAM_LP: Contract;
    BAVAAERO_LP: Contract;
    BOTTOETH_LP: Contract;
    BETUSDC_LP: Contract;
    ICLETH_LP: Contract;
    fBOMBWELS_LP: Contract;
    USDCKLIMA_LP: Contract;
    ETHPDT_LP: Contract;
    ["OVNUSD+_LP"]: Contract;
    ETHKLIMA_LP: Contract;
    TKNAERO_LP: Contract;
    BCTUSDC_LP: Contract;
    ETHWELL_LP: Contract;
    ETHTKN_LP: Contract;
    fBOMBwstETH_LP: Contract;
    cbBTCTAROT_LP: Contract;
    BAVAUSDC_LP: Contract;
    ETHWELS_LP: Contract;
    AEROTAROT_LP: Contract;
    WETHMET_LP: Contract;
    fBOMBcbBTC_LP: Contract;
    ETHTAROT_LP: Contract;
    ETHJARVIS_LP: Contract;
    USDCAERO_LP: Contract;
    // OGNsuperOETHb_LP: Contract;
    USDCcbBTC_LP: Contract;
    ETHcbBTC_LP: Contract;
    ETHUSDC_LP: Contract;

    TOMB: ERC20;
    TSHARE: ERC20;
    FTM: ERC20;
    LQDR: ERC20;
    CREATOR: ERC20;
    fUSDT: ERC20;
    BTC: ERC20;
    FRAX: ERC20;
    MAI: ERC20;

    OBELISK: ERC20;
    GOBELISK: ERC20;
    BOBELISK: ERC20;

    BOAT: ERC20;
    BOMB: ERC20;
    BIFI: ERC20;
    DAI: ERC20;
    DEI: ERC20;
    ATLAS: ERC20;
    gOHM: ERC20;
    DEUS: ERC20;

    ETH: ERC20;
    LRDS: ERC20;
    HBR: ERC20;
    USDbC: ERC20;
    VIRTUAL: ERC20;
    cbBTC: ERC20;
    OX: ERC20;
    RECORD: ERC20;
    RFL: ERC20;
    ION: ERC20;
    SAM: ERC20;
    BAVA: ERC20;
    AERO: ERC20;
    BOTTO: ERC20;
    BET: ERC20;
    USDC: ERC20;
    ICL: ERC20;
    fBOMB: ERC20;
    WELS: ERC20;
    KLIMA: ERC20;
    WELL: ERC20;
    PDT: ERC20;
    OVN: ERC20;
    ["USD+"]: ERC20;
    TKN: ERC20;
    BCT: ERC20;
    wstETH: ERC20;
    TAROT: ERC20;
    WETH: ERC20;
    MET: ERC20;
    JARVIS: ERC20;
    OGN: ERC20;
    superOETHb: ERC20;


    constructor(cfg: Configuration) {
        const { deployments, externalTokens } = cfg;
        const provider = getDefaultProvider();

        // loads contracts from deployments
        this.contracts = {};
        for (const [name, deployment] of Object.entries(deployments)) {
            this.contracts[name] = new Contract(deployment.address, deployment.abi, provider);
        }
        this.externalTokens = {};
        for (const [symbol, [address, decimal]] of Object.entries(externalTokens)) {
            this.externalTokens[symbol] = new ERC20(address, provider, symbol, decimal);
        }
        this.OBELISK = new ERC20(deployments.tomb.address, provider, 'OBELISK');
        this.GOBELISK = new ERC20(deployments.tShare.address, provider, 'GOBELISK');
        this.BOBELISK = new ERC20(deployments.tBond.address, provider, 'BOBELISK');
        this.TOMB = this.externalTokens['TOMB'];
        this.TSHARE = this.externalTokens['TSHARE'];
        this.LQDR = this.externalTokens['LQDR'];
        this.CREATOR = this.externalTokens['CRE8R'];
        this.fUSDT = this.externalTokens['fUSDT'];
        this.USDC = this.externalTokens['USDC'];
        this.BTC = this.externalTokens['BTC'];
        this.ETH = this.externalTokens['ETH'];
        this.FRAX = this.externalTokens['FRAX'];
        this.MAI = this.externalTokens['MAI'];

        this.BOAT = this.externalTokens['BOAT'];
        this.BOMB = this.externalTokens['BOMB'];
        this.BIFI = this.externalTokens['BIFI'];
        this.DAI = this.externalTokens['DAI'];
        this.DEI = this.externalTokens['DEI'];
        this.ATLAS = this.externalTokens['ATLAS'];
        this.gOHM = this.externalTokens['gOHM'];
        this.DEUS = this.externalTokens['DEUS'];

        this.ETH = this.externalTokens['ETH'];
        this.LRDS = this.externalTokens['LRDS'];
        this.HBR = this.externalTokens['HBR'];
        this.USDbC = this.externalTokens['USDbC'];
        this.VIRTUAL = this.externalTokens['VIRTUAL'];
        this.cbBTC = this.externalTokens['cbBTC'];
        this.OX = this.externalTokens['OX'];
        this.RECORD = this.externalTokens['RECORD'];
        this.RFL = this.externalTokens['RFL'];
        this.ION = this.externalTokens['ION'];
        this.SAM = this.externalTokens['SAM'];
        this.BAVA = this.externalTokens['BAVA'];
        this.AERO = this.externalTokens['AERO'];
        this.BOTTO = this.externalTokens['BOTTO'];
        this.BET = this.externalTokens['BET'];
        this.USDC = this.externalTokens['USDC'];
        this.ICL = this.externalTokens['ICL'];
        this.fBOMB = this.externalTokens['fBOMB'];
        this.WELS = this.externalTokens['WELS'];
        this.KLIMA = this.externalTokens['KLIMA'];
        this.WELL = this.externalTokens['WELL'];
        this.PDT = this.externalTokens['PDT'];
        this.OVN = this.externalTokens['OVN'];
        this["USD+"] = this.externalTokens['USD+'];
        this.TKN = this.externalTokens['TKN'];
        this.BCT = this.externalTokens['BCT'];
        this.wstETH = this.externalTokens['wstETH'];
        this.TAROT = this.externalTokens['TAROT'];
        this.WETH = this.externalTokens['WETH'];
        this.MET = this.externalTokens['MET'];
        this.JARVIS = this.externalTokens['JARVIS'];
        this.OGN = this.externalTokens['OGN'];
        this.superOETHb = this.externalTokens['superOETHb'];


        // Uniswap V2 Pair
        this.ETHLRDS_LP = new Contract(externalTokens['ETH-LRDS-LP'][0], IUniswapV2PairABI, provider);
        this.ETHAERO_LP = new Contract(externalTokens['ETH-AERO-LP'][0], IUniswapV2PairABI, provider);
        this.DOLAUSDC_LP = new Contract(externalTokens['DOLA-USDC-LP'][0], IUniswapV2PairABI, provider);
        this.USDzUSDC_LP = new Contract(externalTokens['USDz-USDC-LP'][0], IUniswapV2PairABI, provider);
        this.ETHmsETH_LP = new Contract(externalTokens['ETH-msETH-LP'][0], IUniswapV2PairABI, provider);
        this.USDCMAI_LP = new Contract(externalTokens['USDC-MAI-LP'][0], IUniswapV2PairABI, provider);
        this.msUSDUSDC_LP = new Contract(externalTokens['msUSD-USDC-LP'][0], IUniswapV2PairABI, provider);
        this.HBRUSDbC_LP = new Contract(externalTokens['HBR-USDbC-LP'][0], IUniswapV2PairABI, provider);
        this.VIRTUALcbBTC_LP = new Contract(externalTokens['VIRTUAL-cbBTC-LP'][0], IUniswapV2PairABI, provider);
        this.ETHOX_LP = new Contract(externalTokens['ETH-OX-LP'][0], IUniswapV2PairABI, provider);
        this.ETHRECORD_LP = new Contract(externalTokens['ETH-RECORD-LP'][0], IUniswapV2PairABI, provider);
        this.ETHRFL_LP = new Contract(externalTokens['ETH-RFL-LP'][0], IUniswapV2PairABI, provider);
        this.ETHION_LP = new Contract(externalTokens['ION-ETH-LP'][0], IUniswapV2PairABI, provider);
        this.ETHSAM_LP = new Contract(externalTokens['ETH-SAM-LP'][0], IUniswapV2PairABI, provider);
        this.BAVAAERO_LP = new Contract(externalTokens['BAVA-AERO-LP'][0], IUniswapV2PairABI, provider);
        this.BOTTOETH_LP = new Contract(externalTokens['BOTTO-ETH-LP'][0], IUniswapV2PairABI, provider);
        this.BETUSDC_LP = new Contract(externalTokens['BET-USDC-LP'][0], IUniswapV2PairABI, provider);
        this.ICLETH_LP = new Contract(externalTokens['ICL-ETH-LP'][0], IUniswapV2PairABI, provider);
        this.fBOMBWELS_LP = new Contract(externalTokens['fBOMB-WELS-LP'][0], IUniswapV2PairABI, provider);
        this.USDCKLIMA_LP = new Contract(externalTokens['USDC-KLIMA-LP'][0], IUniswapV2PairABI, provider);
        this.ETHPDT_LP = new Contract(externalTokens['ETH-PDT-LP'][0], IUniswapV2PairABI, provider);
        this["OVNUSD+_LP"] = new Contract(externalTokens['OVN-USD+-LP'][0], IUniswapV2PairABI, provider);
        this.ETHKLIMA_LP = new Contract(externalTokens['ETH-KLIMA-LP'][0], IUniswapV2PairABI, provider);
        this.TKNAERO_LP = new Contract(externalTokens['TKN-AERO-LP'][0], IUniswapV2PairABI, provider);
        this.BCTUSDC_LP = new Contract(externalTokens['BCT-USDC-LP'][0], IUniswapV2PairABI, provider);
        this.ETHWELL_LP = new Contract(externalTokens['ETH-WELL-LP'][0], IUniswapV2PairABI, provider);
        this.ETHTKN_LP = new Contract(externalTokens['ETH-TKN-LP'][0], IUniswapV2PairABI, provider);
        this.fBOMBwstETH_LP = new Contract(externalTokens['fBOMB-wstETH-LP'][0], IUniswapV2PairABI, provider);
        this.cbBTCTAROT_LP = new Contract(externalTokens['cbBTC-TAROT-LP'][0], IUniswapV2PairABI, provider);
        this.BAVAUSDC_LP = new Contract(externalTokens['BAVA-USDC-LP'][0], IUniswapV2PairABI, provider);
        this.ETHWELS_LP = new Contract(externalTokens['ETH-WELS-LP'][0], IUniswapV2PairABI, provider);
        this.AEROTAROT_LP = new Contract(externalTokens['AERO-TAROT-LP'][0], IUniswapV2PairABI, provider);
        this.WETHMET_LP = new Contract(externalTokens['ETH-MET-LP'][0], IUniswapV2PairABI, provider);
        this.fBOMBcbBTC_LP = new Contract(externalTokens['fBOMB-cbBTC-LP'][0], IUniswapV2PairABI, provider);
        this.ETHTAROT_LP = new Contract(externalTokens['ETH-TAROT-LP'][0], IUniswapV2PairABI, provider);
        this.ETHJARVIS_LP = new Contract(externalTokens['ETH-JARVIS-LP'][0], IUniswapV2PairABI, provider);
        this.USDCAERO_LP = new Contract(externalTokens['USDC-AERO-LP'][0], IUniswapV2PairABI, provider);
        // this.OGNsuperOETHb_LP = new Contract(externalTokens['OGN-superOETHb-LP'][0], IUniswapV2PairABI, provider);
        this.USDCcbBTC_LP = new Contract(externalTokens['USDC-cbBTC-LP'][0], IUniswapV2PairABI, provider);
        this.ETHcbBTC_LP = new Contract(externalTokens['ETH-cbBTC-LP'][0], IUniswapV2PairABI, provider);
        this.ETHUSDC_LP = new Contract(externalTokens['ETH-USDC-LP'][0], IUniswapV2PairABI, provider);




        this.OBELISKETHLP = new Contract(externalTokens['ETH-OBELISK-LP'][0], IUniswapV2PairABI, provider);
        this.GOBELISKETHLP = new Contract(externalTokens['ETH-GOBELISK-LP'][0], IUniswapV2PairABI, provider);
        this.TOMBFTMLP = new Contract(externalTokens['TOMB-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.TSHAREFTMLP = new Contract(externalTokens['TSHARE-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.LQDRFTMLP = new Contract(externalTokens['LQDR-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.CREATORFTMLP = new Contract(externalTokens['CRE8R-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.fUSDTFTMLP = new Contract(externalTokens['fUSDT-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.USDCFTMLP = new Contract(externalTokens['USDC-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.BTCFTMLP = new Contract(externalTokens['BTC-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.ETHFTMLP = new Contract(externalTokens['ETH-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.FRAXFTMLP = new Contract(externalTokens['FRAX-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.MAIFTMLP = new Contract(externalTokens['MAI-FTM-LP'][0], IUniswapV2PairABI, provider);

        this.BOMBFTMLP = new Contract(externalTokens['BOMB-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.BIFIFTMLP = new Contract(externalTokens['BIFI-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.DEIUSDCLP = new Contract(externalTokens['DEI-USDC-LP'][0], IUniswapV2PairABI, provider);
        this.gOHMFTMLP = new Contract(externalTokens['gOHM-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.ATLASFTMLP = new Contract(externalTokens['ATLAS-FTM-LP'][0], IUniswapV2PairABI, provider);
        this.DEUSFTMLP = new Contract(externalTokens['DEUS-FTM-LP'][0], IUniswapV2PairABI, provider);

        this.config = cfg;
        this.provider = provider;
    }

    /**
     * @param provider From an unlocked wallet. (e.g. Metamask)
     * @param account An address of unlocked wallet account.
     */
    unlockWallet(provider: any, account: string) {
        const newProvider = provider;
        // const newProvider = new ethers.providers.Web3Provider(provider, this.config.chainId);
        this.signer = newProvider.getSigner(0);
        this.myAccount = account;
        for (const [name, contract] of Object.entries(this.contracts)) {
            this.contracts[name] = contract.connect(this.signer);
        }
        const tokens = [this.OBELISK, this.GOBELISK, this.BOBELISK, ...Object.values(this.externalTokens)];
        for (const token of tokens) {
            token.connect(this.signer);
        }
        this.OBELISKETHLP = this.OBELISKETHLP.connect(this.signer);
        console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
        this.fetchMasonryVersionOfUser()
            .then((version) => (this.masonryVersionOfUser = version))
            .catch((err) => {
                console.error(`Failed to fetch temple version: ${err.stack}`);
                this.masonryVersionOfUser = 'latest';
            });
    }

    get isUnlocked(): boolean {
        return !!this.myAccount;
    }

    //===================================================================
    //===================== GET ASSET STATS =============================
    //===================FROM SPOOKY TO DISPLAY =========================
    //=========================IN HOME PAGE==============================
    //===================================================================

    async getExternalTokenPrice(tokenName: string): Promise<TokenStat> {
        const priceInFTM = await this.getTokenPriceFromCustomApi(this.externalTokens[tokenName]);
        const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
        const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(2);

        return {
            tokenInFtm: priceInFTM,
            priceInDollars: priceOfTombInDollars,
            totalSupply: '0',
            circulatingSupply: '0',
        };
    }

    async getTombStat(): Promise<TokenStat> {
        const { ObeliskGenesisRewardPool } = this.contracts;
        const supply = await this.OBELISK.totalSupply();
        const tombRewardPoolSupply = await this.OBELISK.balanceOf(ObeliskGenesisRewardPool.address);
        const tombCirculatingSupply = supply
            .sub(tombRewardPoolSupply)
        const priceInFTM = await this.getTokenPriceFromCustomApi(this.OBELISK);
        const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
        const priceOfTombInDollars = ((Number(priceInFTM) * Number(priceOfOneFTM))).toFixed(2);

        return {
            tokenInFtm: priceInFTM,
            priceInDollars: priceOfTombInDollars,
            totalSupply: getDisplayBalance(supply, this.OBELISK.decimal, 0),
            circulatingSupply: getDisplayBalance(tombCirculatingSupply, this.OBELISK.decimal, 0),
        };
    }

    /**
     * Calculates various stats for the requested LP
     * @param name of the LP token to load stats for
     * @returns
     */
    async getLPStat(name: string): Promise<LPStat> {
        const lpToken = this.externalTokens[name];
        const lpTokenSupplyBN = await lpToken.totalSupply();
        const lpTokenSupply = getDisplayBalance(lpTokenSupplyBN, lpToken.decimal);
        const token0 = name.startsWith('OBELISK') || name.startsWith('GOBELISK') ? (name.startsWith('OBELISK') ? this.OBELISK : this.GOBELISK) : this.externalTokens[name.substr(0, name.indexOf('-'))];
        const isTomb = name.startsWith('OBELISK');
        const tokenAmountBN = await token0.balanceOf(lpToken.address);
        const tokenAmount = getDisplayBalance(tokenAmountBN, token0.decimal);

        let ftmAmountBN = await this.USDC.balanceOf(lpToken.address);
        let ftmAmount = getDisplayBalance(ftmAmountBN, 6);

        if (name.startsWith('-USDC', name.substr(0, name.indexOf('-')).length)) {
            ftmAmountBN = await this.USDC.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 6);
        }
        else if (name.startsWith('-ETH', name.substr(0, name.indexOf('-')).length)) {
            ftmAmountBN = await this.USDC.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 18);
        }
        else if (name.startsWith('-TOMB', name.substr(0, name.indexOf('-')).length)) {
            ftmAmountBN = await this.TOMB.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 18);
        }
        else if (name.startsWith('-GOBELISK', name.substr(0, name.indexOf('-')).length)) {
            ftmAmountBN = await this.GOBELISK.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 18);
        } else {
            ftmAmountBN = await this.FTM.balanceOf(lpToken.address);
            ftmAmount = getDisplayBalance(ftmAmountBN, 18);
        }
        const tokenAmountInOneLP = Number(tokenAmount) / Number(lpTokenSupply);
        const ftmAmountInOneLP = Number(ftmAmount) / Number(lpTokenSupply);
        const lpTokenPrice = await this.getLPTokenPrice(lpToken, token0, isTomb);
        const lpTokenPriceFixed = Number(lpTokenPrice).toFixed(2).toString();
        const liquidity = (Number(lpTokenSupply) * Number(lpTokenPrice)).toFixed(2).toString();

        console.log("LPS INFO", tokenAmountInOneLP, ftmAmountInOneLP, lpTokenPriceFixed, liquidity, Number(lpTokenSupply).toFixed(2))
        return {
            tokenAmount: tokenAmountInOneLP.toFixed(2).toString(),
            ftmAmount: ftmAmountInOneLP.toFixed(2).toString(),
            priceOfOne: lpTokenPriceFixed,
            totalLiquidity: liquidity,
            totalSupply: Number(lpTokenSupply).toFixed(2).toString(),
        };
    }

    /**
     * Use this method to get price for Dollar
     * @returns TokenStat for BOBELISK
     * priceInFTM
     * priceInDollars
     * TotalSupply
     * CirculatingSupply (always equal to total supply for bonds)
     */
    async getBondStat(): Promise<TokenStat> {
        const { Treasury } = this.contracts;
        const tombStat = await this.getTombStat();
        const bondTombRatioBN = await Treasury.getBondPremiumRate();
        const modifier = bondTombRatioBN / 1e18 > 1 ? bondTombRatioBN / 1e18 : 1;
        const bondPriceInFTM = (Number(tombStat.tokenInFtm) * modifier).toFixed(2);
        const priceOfTBondInDollars = (Number(tombStat.priceInDollars) * modifier).toFixed(2);
        const supply = await this.BOBELISK.displayedTotalSupply();
        return {
            tokenInFtm: bondPriceInFTM,
            priceInDollars: priceOfTBondInDollars,
            totalSupply: supply,
            circulatingSupply: supply,
        };
    }

    /**
     * @returns TokenStat for GOBELISK
     * priceInFTM
     * priceInDollars
     * TotalSupply
     * CirculatingSupply (always equal to total supply for bonds)
     */
    async getShareStat(): Promise<TokenStat> {
        const { CemeteryGobeliskRewardPool } = this.contracts;

        const supply = await this.GOBELISK.totalSupply();

        const priceInETH = await this.getTokenPriceFromCustomApi(this.GOBELISK);
        const tombRewardPoolSupply = await this.GOBELISK.balanceOf(CemeteryGobeliskRewardPool.address);
        const tShareCirculatingSupply = supply.sub(tombRewardPoolSupply);
        const priceOfOneETH = await this.getWFTMPriceFromPancakeswap();
        const priceOfOneUSDC = await this.getExternalTokenPrice("USDC");
        const priceOfSharesInDollars = (Number(priceInETH) * Number(priceOfOneETH) / Number(priceOfOneUSDC.priceInDollars)).toFixed(2);

        return {
            tokenInFtm: priceInETH,
            priceInDollars: priceOfSharesInDollars,
            totalSupply: getDisplayBalance(supply, this.GOBELISK.decimal, 0),
            circulatingSupply: getDisplayBalance(tShareCirculatingSupply, this.GOBELISK.decimal, 0),
        };
    }

    async getTombStatInEstimatedTWAP(): Promise<TokenStat> {
        const { SeigniorageOracle, ObeliskGenesisRewardPool } = this.contracts;
        let expectedPrice = parseUnits('1', 18);
        try {
            expectedPrice = await SeigniorageOracle.twap(this.OBELISK.address, ethers.utils.parseEther('1'));
        } catch {
            console.log('Failed to fetch TWAP, falling back to legacy');
        }

        const supply = await this.OBELISK.totalSupply();
        const tombRewardPoolSupply = await this.OBELISK.balanceOf(ObeliskGenesisRewardPool.address);
        const tombCirculatingSupply = supply.sub(tombRewardPoolSupply);
        return {
            tokenInFtm: getDisplayBalance(expectedPrice, 18),
            priceInDollars: getDisplayBalance(expectedPrice, 18),
            totalSupply: getDisplayBalance(supply, this.OBELISK.decimal, 0),
            circulatingSupply: getDisplayBalance(tombCirculatingSupply, this.OBELISK.decimal, 0),
        };
    }

    async getTombPriceInLastTWAP(): Promise<BigNumber> {
        const { Treasury } = this.contracts;
        return Treasury.getObeliskUpdatedPrice();
    }

    async getBondsPurchasable(): Promise<BigNumber> {
        const { Treasury } = this.contracts;
        return Treasury.getBurnableObeliskLeft();
    }

    /**
     * Calculates the TVL, APR and daily APR of a provided pool/bank
     * @param bank
     * @returns
     */
    async getPoolAPRsOLD(bank: Bank): Promise<PoolStats> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount)
            userAccount = this.myAccount;
        if (bank.contract === "DollarAutocompounder") return;

        const depositToken = bank.depositToken;
        const poolContract = this.contracts[bank.contract];

        const depositTokenPrice = await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken);
        const stakeInPool = await depositToken.balanceOf(bank.address);
        const TVL = Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, depositToken.decimal));
        const stat = bank.earnTokenName === 'OBELISK' ? await this.getTombStat() : await this.getShareStat();
        const tokenPerSecond = await this.getTokenPerSecond(
            bank.earnTokenName,
            bank.contract,
            poolContract,
            bank.depositTokenName,
            bank.poolId
        );

        const tokenPerHour = tokenPerSecond.mul(60).mul(60);
        const totalRewardPricePerYear =
            Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(14 * 24).mul(365)));
        const totalRewardPricePerDay = Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(14 * 24)));
        const totalStakingTokenInPool =
            Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, depositToken.decimal));
        const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool) * 100;
        const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;
        return {
            dailyAPR: dailyAPR.toFixed(2).toString(),
            yearlyAPR: yearlyAPR.toFixed(2).toString(),
            TVL: TVL.toFixed(2).toString(),
        };
    }
    // }

    async getPoolAPRs(bank: Bank): Promise<PoolStats> {
        // get it from the API
        const response = await fetch(`${getBaseApiUrl()}/farm-aprs`);
        const isGenesis = bank.earnTokenName === 'OBELISK';
        const data = await response.json();

        if (isGenesis) {
            return data.genesis[bank.depositTokenName] ? data.genesis[bank.depositTokenName] : { dailyAPR: '0.00', yearlyAPR: '0.00', TVL: '0.00' };
        } else {
            return data.farms[bank.depositTokenName] ? data.farms[bank.depositTokenName] : { dailyAPR: '0.00', yearlyAPR: '0.00', TVL: '0.00' };
        }
    }

    async getAllPoolAPRs(isGenesis: boolean): Promise<PoolStats> {
        // get it from the API
        const response = await fetch(`${getBaseApiUrl()}/farm-aprs`);
        const data = await response.json();

        if (isGenesis) {
            return data.genesis;
        } else {
            return data.farms;
        }
    }

    /**
     * Method to return the amount of tokens the pool yields per second
     * @param earnTokenName the name of the token that the pool is earning
     * @param contractName the contract of the pool/bank
     * @param poolContract the actual contract of the pool
     * @returns
     */
    async getTokenPerSecond(
        earnTokenName: string,
        contractName: string,
        poolContract: Contract,
        depositTokenName: string,
        poolId: number,
    ) {
        if (earnTokenName === 'OBELISK') {
            if (!contractName.endsWith('TombRewardPool')) {

                // const rewardPerSecond = await poolContract.obeliskPerSecond();
                const poolInfo = await poolContract.poolInfo(poolId);
                const poolAlloc = poolInfo.poolObeliskPerSec;
                return poolAlloc;
            }
            // const poolStartTime = await poolContract.poolStartTime();
            // const startDateTime = new Date(poolStartTime.toNumber() * 1000);
            // const TWO_DAYS = 2 * 24 * 60 * 60 * 1000;
            // if (Date.now() - startDateTime.getTime() > TWO_DAYS) {
            //   return await poolContract.epochTombPerSecond(1);
            // }
            // return await poolContract.epochTombPerSecond(0);
        }

        // const rewardPerSecond = await poolContract.sharePerSecond();
        const poolInfo = await poolContract.poolInfo(poolId);
        const poolAlloc = poolInfo.poolGobeliskPerSec;
        return poolAlloc;
        // if (depositTokenName.startsWith('ETH-OBELISK-LP')) {
        //     return rewardPerSecond.mul(60000).div(100000);
        // } else if (depositTokenName.startsWith('ETH-GOBELISK-LP')) {
        //     return rewardPerSecond.mul(40000).div(100000);
        // } else {
        //     return rewardPerSecond.mul(4000).div(50000);
        // }
    }

    /**
     * Method to calculate the tokenPrice of the deposited asset in a pool/bank
     * If the deposited token is an LP it will find the price of its pieces
     * @param tokenName
     * @param pool
     * @param token
     * @returns
     */
    async getDepositTokenPriceInDollars(tokenName: string, token: ERC20) {
        let tokenPrice;
        const priceOfOneFtmInDollars = await this.getWFTMPriceFromPancakeswap();
        if (tokenName === 'ETH') {
            tokenPrice = priceOfOneFtmInDollars;
        } else {
            // if tokenName ends in -LP, it is an LP token
            if (tokenName.endsWith('-LP')) {
                tokenPrice = await this.getLPTokenPrice(token, this.externalTokens[tokenName.substr(0, tokenName.indexOf('-'))], false);
            }
            else {
                tokenPrice = await this.getTokenPriceFromCustomApi(token);
                tokenPrice = (Number(tokenPrice) * Number(priceOfOneFtmInDollars)).toString();
            }
        }
        return tokenPrice;
    }

    //===================================================================
    //===================== GET ASSET STATS =============================
    //=========================== END ===================================
    //===================================================================

    async getCurrentEpoch(): Promise<BigNumber> {
        const { Treasury } = this.contracts;
        return Treasury.epoch();
    }

    async getBondOraclePriceInLastTWAP(): Promise<BigNumber> {
        const { Treasury } = this.contracts;
        return Treasury.getBondPremiumRate();
    }

    /**
     * Buy bonds with cash.
     * @param amount amount of cash to purchase bonds with.
     */
    async buyBonds(amount: string | number): Promise<TransactionResponse> {
        const { Treasury } = this.contracts;
        const treasuryTombPrice = await Treasury.getObeliskPrice();
        return await Treasury.buyBonds(decimalToBalance(amount), treasuryTombPrice);
    }

    /**
     * Redeem bonds for cash.
     * @param amount amount of bonds to redeem.
     */
    async redeemBonds(amount: string): Promise<TransactionResponse> {
        const { Treasury } = this.contracts;
        const priceForTomb = await Treasury.getObeliskPrice();
        return await Treasury.redeemBonds(decimalToBalance(amount), priceForTomb);
    }

    async getTotalValueLockedOld(): Promise<Number> {
        let totalValue = 0;
        for (const bankInfo of Object.values(bankDefinitions)) {
            const pool = this.contracts[bankInfo.contract];
            const token = bankInfo.depositTokenName === "OBELISK" ? this.OBELISK : bankInfo.depositTokenName === "GOBELISK" ? this.GOBELISK : this.externalTokens[bankInfo.depositTokenName];
            const tokenPrice = await this.getDepositTokenPriceInDollars(bankInfo.depositTokenName, token);
            const tokenAmountInPool = await token.balanceOf(pool.address);
            const value = Number(getDisplayBalance(tokenAmountInPool, token.decimal)) * Number(tokenPrice);
            const poolValue = Number.isNaN(value) ? 0 : value;
            totalValue += poolValue;
        }

        const GOBELISKPrice = (await this.getShareStat()).priceInDollars;
        const masonrytShareBalanceOf = await this.GOBELISK.balanceOf(this.currentMasonry().address);
        const masonryTVL = Number(getDisplayBalance(masonrytShareBalanceOf, this.GOBELISK.decimal)) * Number(GOBELISKPrice);

        return totalValue + masonryTVL;
    }

    async getTotalValueLocked(): Promise<Number> {
        const response = await fetch(`${getBaseApiUrl()}/tvl`);
        const data = await response.json();
        return data ? data.tvl : 0;
    }

    async getTotalTreasuryAssets(): Promise<Number> {
        const response = await fetch(`${getBaseApiUrl()}/treasury`);
        const data = await response.json();
        return data ? data.tvl : 0;
    }

    /**
     * Calculates the price of an LP token
     * Reference https://github.com/DefiDebauchery/discordpricebot/blob/4da3cdb57016df108ad2d0bb0c91cd8dd5f9d834/pricebot/pricebot.py#L150
     * @param lpToken the token under calculation
     * @param token the token pair used as reference (the other one would be FTM in most cases)
     * @param isTomb sanity check for usage of OBELISK token or GOBELISK
     * @returns price of the LP token
     */
    async getLPTokenPriceOld(lpToken: ERC20, token: ERC20, isTomb: boolean): Promise<string> {
        const totalSupply = getDisplayBalance(await lpToken.totalSupply(), lpToken.decimal, lpToken.decimal);
        //Get amount of tokenA
        const tokenSupply = getDisplayBalance(await token.balanceOf(lpToken.address), token.decimal, token.decimal);
        // const stat = isTomb === true ? await this.getTombStat() : await this.getShareStat();
        const stat = token.symbol === "OBELISK" || token.symbol === "GOBELISK" ? (isTomb === true ? await this.getTombStat() : await this.getShareStat()) : await this.getExternalTokenPrice(token.symbol);

        const priceOfToken = stat.priceInDollars;
        const tokenInLP = Number(tokenSupply) / Number(totalSupply);
        const tokenPrice = (Number(priceOfToken) * tokenInLP * 2) //We multiply by 2 since half the price of the lp token is the price of each piece of the pair. So twice gives the total
            .toString();
        return tokenPrice;
    }

    async earnedFromBank(
        poolName: ContractName,
        earnTokenName: String,
        poolId: Number,
        account = this.myAccount,
    ): Promise<BigNumber> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (account)
            userAccount = account;

        const pool = this.contracts[poolName];
        try {
            if (earnTokenName === 'OBELISK') {
                return await pool.pendingOBELISK(poolId, userAccount);
            } else {
                return await pool.pendingShare(poolId, userAccount);
            }
        } catch (err: any) {
            console.error(`Failed to call earned() on pool ${pool.address}: ${err.stack}`);
            return BigNumber.from(0);
        }
    }

    async stakedBalanceOnBank(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<BigNumber> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (account)
            userAccount = account;

        const pool = this.contracts[poolName];
        try {
            let userInfo = await pool.userInfo(poolId, userAccount);
            return await userInfo.amount;
        } catch (err: any) {
            console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err.stack}`);
            return BigNumber.from(0);
        }
    }

    /**
     * Deposits token to given pool.
     * @param poolName A name of pool contract.
     * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
     * @returns {string} Transaction hash
     */

    async stake(poolName: ContractName, poolId: Number, sectionInUI: Number, amount: BigNumber): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        return sectionInUI !== 3
            ? await pool.deposit(poolId, amount)
            : await pool.create(poolId, amount);
    }

    /**
     * Withdraws token from given pool.
     * @param poolName A name of pool contract.
     * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
     * @returns {string} Transaction hash
     */
    async unstake(poolName: ContractName, poolId: Number, amount: BigNumber): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        return await pool.withdraw(poolId, amount);
    }

    /**
     * Transfers earned token reward from given pool to my account.
     */

    async harvest(poolName: ContractName, poolId: Number, sectionInUI: Number): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        //By passing 0 as the amount, we are asking the contract to only redeem the reward and not the currently staked token
        return sectionInUI !== 3
            ? await pool.withdraw(poolId, 0)
            : await pool.claim();
    }

    /**
     * Harvests and withdraws deposited tokens from the pool.
     */
    async exit(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        let userInfo = await pool.userInfo(poolId, account);
        return await pool.withdraw(poolId, userInfo.amount);
    }

    async fetchMasonryVersionOfUser(): Promise<string> {
        return 'latest';
    }

    currentMasonry(): Contract {
        if (!this.masonryVersionOfUser) {
            //throw new Error('you must unlock the wallet to continue.');
        }
        return this.contracts.Masonry;
    }

    isOldMasonryMember(): boolean {
        return this.masonryVersionOfUser !== 'latest';
    }

    async getLPTokenPrice(lpToken: ERC20, token: ERC20, isTomb: boolean): Promise<string> {
        const response = await fetch(`${getBaseApiUrl()}/lp-prices`);
        const data = await response.json();
        return data[lpToken.symbol] ? data[lpToken.symbol].priceUSD : '1';
    }


    async getTokenPriceFromCustomApi(tokenContract: ERC20): Promise<string> {
        const response = await fetch(`${getBaseApiUrl()}/token-prices`);
        // const response = await fetch(`https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${tokenContract.address}&vs_currencies=usd`);
        const data = await response.json();
        return data[tokenContract.symbol] ? data[tokenContract.symbol].priceETH : '1';
    }

    // function to get multiple token prices
    async getMultipleTokenPricesFromCustomApi(tokens: string[]): Promise<any> {
        const response = await fetch(`${getBaseApiUrl()}/token-prices`);
        const data = await response.json();

        // Create a single object with tokenSymbol: price pairs
        const formatted = tokens.reduce((acc: any, symbol) => {
            acc[symbol] = data[symbol] ? data[symbol].priceUSD : 1;
            return acc;
        }, {});

        return formatted;
    }

    /*async getTokenPriceFromSpookySwap(tokenContract: ERC20): Promise<string> {
        const ready = await this.provider.ready;
        if (!ready) return;
        const chainId = 8453;
        // const { chainId } = this.config;

        const { ETH } = this.externalTokens;

        const weth = new TokenSpooky(chainId, ETH.address, ETH.decimal);
        const token = new TokenSpooky(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
        try {
            const wethToToken = await FetcherSpooky.fetchPairData(weth, token, this.provider);
            const liquidityToken = wethToToken.liquidityToken;
            let ftmBalanceInLP = await ETH.balanceOf(liquidityToken.address);
            let ftmAmount = Number(getDisplayBalance(ftmBalanceInLP, ETH.decimal, 8));
            let shibaBalanceInLP = await tokenContract.balanceOf(liquidityToken.address);
            let shibaAmount = Number(getDisplayBalance(shibaBalanceInLP, tokenContract.decimal, tokenContract.decimal));
            const priceOfOneFtmInDollars = await this.getWFTMPriceFromPancakeswap();
            let priceOfShiba = (ftmAmount / shibaAmount) * Number(priceOfOneFtmInDollars);
            return priceOfShiba.toString();
        } catch (err) {
            console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
        }
    }

    async getTokenPriceFromPancakeswap(tokenContract: ERC20): Promise<string> {
        const ready = await this.provider.ready;
        if (!ready) return;
        const chainId = 8453;
        // const { chainId } = this.config;
        const { ETH } = this.config.externalTokens;

        const weth = new TokenSpooky(chainId, ETH[0], ETH[1]);
        const token = new TokenSpooky(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
        try {
            const wethToToken = await FetcherSpooky.fetchPairData(weth, token, this.provider);
            const priceInBUSD = new RouteSpooky([wethToToken], token);

            return priceInBUSD.midPrice.toFixed(4);
        } catch (err) {
            console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
            return '0';
        }
    }

    async getTokenPriceFromSpiritswap(tokenContract: ERC20): Promise<string> {
        const ready = await this.provider.ready;
        if (!ready) return;
        const chainId = 8453;
        // const { chainId } = this.config;

        const { ETH } = this.externalTokens;

        const weth = new TokenSpooky(chainId, ETH.address, ETH.decimal);
        const token = new TokenSpooky(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
        try {
            const wethToToken = await FetcherSpooky.fetchPairData(weth, token, this.provider);
            const liquidityToken = wethToToken.liquidityToken;
            let ftmBalanceInLP = await ETH.balanceOf(liquidityToken.address);
            let ftmAmount = Number(getDisplayBalance(ftmBalanceInLP, ETH.decimal, 8));
            let shibaBalanceInLP = await tokenContract.balanceOf(liquidityToken.address);
            let shibaAmount = Number(getDisplayBalance(shibaBalanceInLP, tokenContract.decimal, tokenContract.decimal));
            const priceOfOneFtmInDollars = await this.getWFTMPriceFromPancakeswap();
            let priceOfShiba = (ftmAmount / shibaAmount) * Number(priceOfOneFtmInDollars);
            return priceOfShiba.toString();
        } catch (err) {
            console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
        }
    }*/

    async getWFTMPriceFromPancakeswap(): Promise<string> {
        const response = await fetch(`${getBaseApiUrl()}/token-prices`);
        // const response = await fetch(`https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${tokenContract.address}&vs_currencies=usd`);
        const data = await response.json();
        return data["ETH"] ? data["ETH"].priceUSD : '3500';
    }

    // async getWFTMPriceFromPancakeswap(): Promise<string> {
    //     const ready = await this.provider.ready;
    //     if (!ready) return;
    //     const { ETH, USDC } = this.externalTokens;
    //     try {
    //         const fusdt_weth_lp_pair = this.externalTokens['ETH-USDC-LP'];
    //         let ftm_amount_BN = await ETH.balanceOf(fusdt_weth_lp_pair.address);
    //         let ftm_amount = Number(getFullDisplayBalance(ftm_amount_BN, ETH.decimal));
    //         let fusdt_amount_BN = await USDC.balanceOf(fusdt_weth_lp_pair.address);
    //         let fusdt_amount = Number(getFullDisplayBalance(fusdt_amount_BN, USDC.decimal));
    //         return ((fusdt_amount / Number(1)) / ftm_amount).toString();
    //     } catch (err) {
    //         console.error(`Failed to fetch token price of WETH: ${err}`);
    //     }
    // }

    //===================================================================
    //===================================================================
    //===================== MASONRY METHODS =============================
    //===================================================================
    //===================================================================

    async getMasonryAPR() {
        const Masonry = this.currentMasonry();
        const latestSnapshotIndex = await Masonry.latestSnapshotIndex();
        const lastHistory = await Masonry.masonryHistory(latestSnapshotIndex);

        const lastRewardsReceived = lastHistory[1];

        const GOBELISKPrice = (await this.getShareStat()).priceInDollars;
        const OBELISKPrice = (await this.getTombStat()).priceInDollars;
        const epochRewardsPerShare = lastRewardsReceived / 1e18;

        //Mgod formula
        const amountOfRewardsPerDay = epochRewardsPerShare * Number(OBELISKPrice) * 4;
        const masonrytShareBalanceOf = await this.GOBELISK.balanceOf(Masonry.address);
        const masonryTVL = Number(getDisplayBalance(masonrytShareBalanceOf, this.GOBELISK.decimal)) * Number(GOBELISKPrice);
        const realAPR = ((amountOfRewardsPerDay * 100) / masonryTVL) * 365;
        return realAPR;
    }

    /**
     * Checks if the user is allowed to retrieve their reward from the Masonry
     * @returns true if user can withdraw reward, false if they can't
     */
    async hasAgreedToVaultTerms(contract: Contract): Promise<boolean> {
        return await contract.hasReadAndAcceptedTerms(this.myAccount);
    }

    /**
     * Checks if the user is allowed to retrieve their reward from the Masonry
     * @returns true if user can withdraw reward, false if they can't
     */
    async canUserClaimRewardFromMasonry(): Promise<boolean> {
        const Masonry = this.currentMasonry();
        return await Masonry.canClaimReward(this.myAccount);
    }

    /**
     * Checks if the user is allowed to retrieve their reward from the Masonry
     * @returns true if user can withdraw reward, false if they can't
     */
    async canUserUnstakeFromMasonry(): Promise<boolean> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount)
            userAccount = this.myAccount;

        const Masonry = this.currentMasonry();
        const canWithdraw = await Masonry.canWithdraw(userAccount);
        const stakedAmount = await this.getStakedSharesOnMasonry();
        const notStaked = Number(getDisplayBalance(stakedAmount, this.GOBELISK.decimal)) === 0;
        const result = notStaked ? true : canWithdraw;
        return result;
    }

    async timeUntilClaimRewardFromMasonry(): Promise<BigNumber> {
        // const Masonry = this.currentMasonry();
        // const mason = await Masonry.masons(this.myAccount);
        return BigNumber.from(0);
    }

    async getTotalStakedInMasonry(): Promise<BigNumber> {
        const Masonry = this.currentMasonry();
        return await Masonry.totalSupply();
    }

    async stakeShareToMasonry(amount: string): Promise<TransactionResponse> {
        if (this.isOldMasonryMember()) {
            throw new Error("you're using old temple. please withdraw and deposit the GOBELISK again.");
        }
        const Masonry = this.currentMasonry();
        return await Masonry.stake(decimalToBalance(amount));
    }

    async getStakedSharesOnMasonry(): Promise<BigNumber> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount)
            userAccount = this.myAccount;

        const Masonry = this.currentMasonry();
        return await Masonry.balanceOf(userAccount);
    }

    async getEarningsOnMasonry(): Promise<BigNumber> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount)
            userAccount = this.myAccount;

        const Masonry = this.currentMasonry();
        return await Masonry.earned(userAccount);
    }

    async withdrawShareFromMasonry(amount: string): Promise<TransactionResponse> {
        const Masonry = this.currentMasonry();
        return await Masonry.withdraw(decimalToBalance(amount));
    }

    async harvestCashFromMasonry(): Promise<TransactionResponse> {
        const Masonry = this.currentMasonry();
        if (this.masonryVersionOfUser === 'v1') {
            return await Masonry.claimDividends();
        }
        return await Masonry.claimReward();
    }

    async exitFromMasonry(): Promise<TransactionResponse> {
        const Masonry = this.currentMasonry();
        return await Masonry.exit();
    }

    async getTreasuryNextAllocationTime(): Promise<AllocationTime> {
        const { Treasury } = this.contracts;
        const nextEpochTimestamp: BigNumber = await Treasury.nextEpochPoint();
        const nextAllocation = new Date(nextEpochTimestamp.mul(1000).toNumber());
        const prevAllocation = new Date(Date.now());

        return { from: prevAllocation, to: nextAllocation };
    }
    /**
     * This method calculates and returns in a from to to format
     * the period the user needs to wait before being allowed to claim
     * their reward from the masonry
     * @returns Promise<AllocationTime>
     */
    async getUserClaimRewardTime(): Promise<AllocationTime> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount)
            userAccount = this.myAccount;

        const { Masonry, Treasury } = this.contracts;
        const nextEpochTimestamp = await Masonry.nextEpochPoint(); //in unix timestamp
        const currentEpoch = await Masonry.epoch();
        const mason = await Masonry.masons(userAccount);
        const startTimeEpoch = mason.epochTimerStart;
        const period = await Treasury.PERIOD();
        const periodInHours = period / 60 / 60; // 6 hours, period is displayed in seconds which is 21600
        const rewardLockupEpochs = await Masonry.rewardLockupEpochs();
        const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(rewardLockupEpochs);

        const fromDate = new Date(Date.now());
        if (targetEpochForClaimUnlock - currentEpoch <= 0) {
            return { from: fromDate, to: fromDate };
        } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
            const toDate = new Date(nextEpochTimestamp * 1000);
            return { from: fromDate, to: toDate };
        } else {
            const toDate = new Date(nextEpochTimestamp * 1000);
            const delta = targetEpochForClaimUnlock - currentEpoch - 1;
            const endDate = moment(toDate)
                .add(delta * periodInHours, 'hours')
                .toDate();
            return { from: fromDate, to: endDate };
        }
    }

    /**
     * This method calculates and returns in a from to to format
     * the period the user needs to wait before being allowed to unstake
     * from the masonry
     * @returns Promise<AllocationTime>
     */
    async getUserUnstakeTime(): Promise<AllocationTime> {
        let userAccount = "0x187b7579DE421E30BfAd0b46625a7455AB552975"
        if (this.myAccount)
            userAccount = this.myAccount;

        const { Masonry, Treasury } = this.contracts;
        const nextEpochTimestamp = await Masonry.nextEpochPoint();
        const currentEpoch = await Masonry.epoch();
        const mason = await Masonry.masons(userAccount);
        const startTimeEpoch = mason.epochTimerStart;
        const period = await Treasury.PERIOD();
        const PeriodInHours = period / 60 / 60;
        const withdrawLockupEpochs = await Masonry.withdrawLockupEpochs();
        const fromDate = new Date(Date.now());
        const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(withdrawLockupEpochs);
        const stakedAmount = await this.getStakedSharesOnMasonry();
        if (currentEpoch <= targetEpochForClaimUnlock && Number(stakedAmount) === 0) {
            return { from: fromDate, to: fromDate };
        } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
            const toDate = new Date(nextEpochTimestamp * 1000);
            return { from: fromDate, to: toDate };
        } else {
            const toDate = new Date(nextEpochTimestamp * 1000);
            const delta = targetEpochForClaimUnlock - Number(currentEpoch) - 1;
            const endDate = moment(toDate)
                .add(delta * PeriodInHours, 'hours')
                .toDate();
            return { from: fromDate, to: endDate };
        }
    }

    async watchAssetInMetamask(assetName: string): Promise<boolean> {
        const { ethereum } = window as any;
        if (ethereum && ethereum.networkVersion === config.chainId.toString()) {
            let asset;
            let assetUrl;
            if (assetName === 'OBELISK') {
                asset = this.OBELISK;
                assetUrl = 'https://i.ibb.co/5GTmcxn/obelisk-logo.png';
            } else if (assetName === 'GOBELISK') {
                asset = this.GOBELISK;
                assetUrl = 'https://i.ibb.co/nkSChnR/golden-obelisk-logo.png';
            } else if (assetName === 'BOBELISK') {
                asset = this.BOBELISK;
                assetUrl = 'https://i.ibb.co/gtwsPzN/bobelisk-logo.png';
            }
            await ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: asset.address,
                        symbol: asset.symbol,
                        decimals: 18,
                        image: assetUrl,
                    },
                },
            });
        }
        return true;
    }

    async provideTombFtmLP(ftmAmount: string, tombAmount: BigNumber): Promise<TransactionResponse> {
        const { TaxOffice } = this.contracts;

        return await TaxOffice.addLiquidityUnderPeg(this.externalTokens["USDC"].address, tombAmount, parseUnits(ftmAmount, 6), tombAmount, parseUnits(ftmAmount, 6));
    }

    async quoteFromSpooky(tokenAmount: string, tokenName: string): Promise<string> {
        const { SpookyRouter } = this.contracts;
        const { _reserve0, _reserve1 } = await this.OBELISKETHLP.getReserves();
        let quote;
        if (tokenName === 'OBELISK') {
            quote = await SpookyRouter.quote(parseUnits(tokenAmount), _reserve1, _reserve0);
        } else {
            quote = await SpookyRouter.quote(parseUnits(tokenAmount), _reserve0, _reserve1);
        }
        return (quote / (tokenName === "OBELISK" ? 1e18 : 1e18)).toString();
    }

    /**
     * @returns an array of the regulation events till the most up to date epoch
     */
    async listenForRegulationsEvents(): Promise<any> {
        const { Treasury } = this.contracts;

        const treasuryDaoFundedFilter = Treasury.filters.DaoFundFunded();
        const treasuryDevFundedFilter = Treasury.filters.DevFundFunded();
        const treasuryMasonryFundedFilter = Treasury.filters.MasonryFunded();
        const boughtBondsFilter = Treasury.filters.BoughtBonds();
        const redeemBondsFilter = Treasury.filters.RedeemedBonds();

        let epochBlocksRanges: any[] = [];
        let masonryFundEvents = await Treasury.queryFilter(treasuryMasonryFundedFilter);
        var events: any[] = [];
        masonryFundEvents.forEach(function callback(value, index) {
            events.push({ epoch: index + 1 });
            events[index].masonryFund = getDisplayBalance(value.args[1]);
            if (index === 0) {
                epochBlocksRanges.push({
                    index: index,
                    startBlock: value.blockNumber,
                    boughBonds: 0,
                    redeemedBonds: 0,
                });
            }
            if (index > 0) {
                epochBlocksRanges.push({
                    index: index,
                    startBlock: value.blockNumber,
                    boughBonds: 0,
                    redeemedBonds: 0,
                });
                epochBlocksRanges[index - 1].endBlock = value.blockNumber;
            }
        });

        epochBlocksRanges.forEach(async (value, index) => {
            events[index].bondsBought = await this.getBondsWithFilterForPeriod(
                boughtBondsFilter,
                value.startBlock,
                value.endBlock,
            );
            events[index].bondsRedeemed = await this.getBondsWithFilterForPeriod(
                redeemBondsFilter,
                value.startBlock,
                value.endBlock,
            );
        });
        let DEVFundEvents = await Treasury.queryFilter(treasuryDevFundedFilter);
        DEVFundEvents.forEach(function callback(value, index) {
            events[index].devFund = getDisplayBalance(value.args[1]);
        });
        let DAOFundEvents = await Treasury.queryFilter(treasuryDaoFundedFilter);
        DAOFundEvents.forEach(function callback(value, index) {
            events[index].daoFund = getDisplayBalance(value.args[1]);
        });
        return events;
    }

    /**
     * Helper method
     * @param filter applied on the query to the treasury events
     * @param from block number
     * @param to block number
     * @returns the amount of bonds events emitted based on the filter provided during a specific period
     */
    async getBondsWithFilterForPeriod(filter: EventFilter, from: number, to: number): Promise<number> {
        const { Treasury } = this.contracts;
        const bondsAmount = await Treasury.queryFilter(filter, from, to);
        return bondsAmount.length;
    }

    async estimateZapIn(tokenName: string, lpName: string, amount: string): Promise<number[]> {
        const { zapper } = this.contracts;
        const lpToken = this.externalTokens[lpName];
        let estimate;
        if (tokenName === ETH_TICKER) {
            estimate = await zapper.estimateZapIn(lpToken.address, SPIRIT_ROUTER_ADDR, parseUnits(amount, 18));
        } else {
            const token = tokenName === OBELISK_TICKER ? this.OBELISK : this.GOBELISK;
            estimate = await zapper.estimateZapInToken(
                token.address,
                lpToken.address,
                SPIRIT_ROUTER_ADDR,
                parseUnits(amount, 18),
            );
        }
        return [estimate[0] / 1e18, estimate[1] / 1e18];
    }
    async zapIn(tokenName: string, lpName: string, amount: string): Promise<TransactionResponse> {
        const { zapper } = this.contracts;
        const lpToken = this.externalTokens[lpName];
        if (tokenName === ETH_TICKER) {
            let overrides = {
                value: parseUnits(amount, 18),
            };
            return await zapper.zapIn(lpToken.address, SPIRIT_ROUTER_ADDR, this.myAccount, overrides);
        } else {
            const token = tokenName === OBELISK_TICKER ? this.OBELISK : this.GOBELISK;
            return await zapper.zapInToken(
                token.address,
                parseUnits(amount, 18),
                lpToken.address,
                SPIRIT_ROUTER_ADDR,
                this.myAccount,
            );
        }
    }
    async swapTBondToTShare(tbondAmount: BigNumber): Promise<TransactionResponse> {
        const { TShareSwapper } = this.contracts;
        return await TShareSwapper.swapTBondToTShare(tbondAmount);
    }
    async estimateAmountOfTShare(tbondAmount: string): Promise<string> {
        const { TShareSwapper } = this.contracts;
        try {
            const estimateBN = await TShareSwapper.estimateAmountOfTShare(parseUnits(tbondAmount, 18));
            return getDisplayBalance(estimateBN, 18, 6);
        } catch (err) {
            console.error(`Failed to fetch estimate tshare amount: ${err}`);
        }
    }

    async getTShareSwapperStat(address: string): Promise<TShareSwapperStat> {
        const { TShareSwapper } = this.contracts;
        const tshareBalanceBN = await TShareSwapper.getTShareBalance();
        const tbondBalanceBN = await TShareSwapper.getTBondBalance(address);
        // const tombPriceBN = await TShareSwapper.getTombPrice();
        // const tsharePriceBN = await TShareSwapper.getTSharePrice();
        const rateTSharePerTombBN = await TShareSwapper.getTShareAmountPerTomb();
        const tshareBalance = getDisplayBalance(tshareBalanceBN, 18, 5);
        const tbondBalance = getDisplayBalance(tbondBalanceBN, 18, 5);
        return {
            tshareBalance: tshareBalance.toString(),
            tbondBalance: tbondBalance.toString(),
            // tombPrice: tombPriceBN.toString(),
            // tsharePrice: tsharePriceBN.toString(),
            rateTSharePerTomb: rateTSharePerTombBN.toString(),
        };
    }

    /* Temple new functions */

    async allocateSeigniorage(): Promise<TransactionResponse> {
        const treasury = this.contracts["Treasury"];
        return await treasury.allocateSeigniorage();
    }
}
